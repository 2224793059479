import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path:'',
  //   redirect:'/'
  // },
  {
    path:'/',
    name:'HomeView',
    component:()=>import('@/views/index.vue') ,
    meta:{
      title:'千行科技官网'
    }
  },
  {
    path:'/bzddt',
    component:()=>import('@/views/bzddtGame.vue'),
    meta:{
      title:'百战斗斗堂官网'
    }
  },
  {
    path:'/gjzq',
    component:()=>import('@/views/footBall.vue'),
    meta:{
      title:'冠军足球官网'
    }
  },
  {
    path:'/ddt',
    component:()=>import('@/views/ddtGame.vue'),
    meta:{
      title:'斗斗堂官网'
    }
  },
  {
    path:'/product',
    component:()=>import('@/views/gameProduct.vue'),
    meta:{
      title:'产品信息'
    }
  },
  {
    path:'/understand',
    component:()=>import('@/views/understand.vue'),
    meta:{
      title:'了解我们'
    }
  },
  {
    path:'/contact',
    component:()=>import('@/views/contact.vue'),
    meta:{
      title:'联系我们'
    }
  },
  {
    path:'/privacy_en',
    component:()=>import('@/views/privacyEN.vue'),
    meta:{
      title:'privacy'
    }
  },
  {
    path:'/privacy_ch',
    component:()=>import('@/views/privacyCH.vue'),
    meta:{
      title:'隐私政策'
    }
  },
  {
    path:'/agreement',
    component:()=>import('@/views/agreement.vue'),
    meta:{
      title:'用户协议'
    }
  },
  // {
  //   path:'/clean_act',
  //   component:()=>import('@/views/deleteUser.vue'),
  //   meta:{
  //     title:'删除账号'
  //   }
  // }
]

const router = new VueRouter({
  mode:'history',
  base:process.env.BASE_URL,
  base:'/',
  routes,
  //对于页面跳转，全部都返回到页面的顶部
  scrollBehavior(to,from,savaedPosition){
    return {x:0,y:0}
  }
})
export default router
