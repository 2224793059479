<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted(){
  },
  methods:{
    
  }
}
</script>


<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  // min-width: 1280px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 767px){
  #app {
  width: 100%;
  height: 100%;
  min-width: unset;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
}
</style>
